import React from 'react';
import Layout from "../components/layout";

export default ({pageContext}) => (
	<Layout>
		<h1>
			{pageContext.title}
		</h1>
		<p>{pageContext.date}</p>
		<div dangerouslySetInnerHTML={{__html: pageContext.content}} />
	</Layout>
);
